<template>
  <!-- 根据是否传入closeDrawer函数判断如何显示导航菜单 -->
  <el-menu
    :default-active="this.$route.path.replace('/home', '')"
    class="el-menu-vertical-demo"
    :collapse="closeDrawer ? false : isCollapse"
    :unique-opened="true"
    background-color="#28333e"
    text-color="#b2b3b6"
    active-text-color="#fafafa"
    @select="closeDrawer ? closeDrawer() : null"
  >
    <div class="logo">
      <img src="../assets/run.png" />
      <span v-show="closeDrawer ? true : !isCollapse">竹水云代步</span>
    </div>
    <el-menu-item
      v-for="item in menu"
      :key="item.name"
      :index="item.path"
      @click="handleSelect(item)"
    >
      <i :class="item.icon"></i>
      <span slot="title">{{ item.title }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "Aside",
  data() {
    return {
      menu: [
        {
          path: "",
          name: "home",
          title: "首页",
          icon: "el-icon-s-home",
        },
        {
          path: "/relation",
          name: "relation",
          title: "关联账号",
          icon: "el-icon-link",
        },
        {
          path: "/log",
          name: "log",
          title: "运行日志",
          icon: "el-icon-notebook-2",
        },
        {
          path: "/repass",
          name: "repass",
          title: "更新密码",
          icon: "el-icon-edit",
        },
      ],
    };
  },
  props: ["handleSelect", "closeDrawer"],
  computed: {
    isCollapse() {
      return this.$store.state.isCollapse;
    },
  },
};
</script>

<style scoped lang="less">
.el-menu {
  border: 0;
  .logo {
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    color: #ddd;
    background-color: #192027;
    height: 60px;
    line-height: 60px;
    text-align: center;
    img {
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin: 0 0 0 12px;
    }
  }
  .el-menu-item {
    height: 50px;
    line-height: 50px;
  }
}

.is-active {
  background-color: #1e9fff !important;
}
</style>