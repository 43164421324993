<template>
  <div>
    <span class="header-top">
      <span class="collage">
        <!-- 侧边栏图标 -->
        <i v-if="wWidth >= 1024"
          :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          @click="changeCollapse"
        ></i>
        <!-- 抽屉图标 -->
        <i
          v-else
          :class="isDrawer ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
          @click="changeDrawer"
        ></i>
      </span>
      <span class="title">云代步</span>
      <div class="header-right">
        <span @click="refresh" class="refresh"><i class="el-icon-refresh"></i></span>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="exit" icon="el-icon-circle-close">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </span>
    <div class="header-bottom">
      <el-tag
        v-for="(item, index) in $store.state.tagList"
        :key="index"
        :closable="item.name !== 'home'"
        effect="plain"
        :type="$route.name === item.name ? 'primary' : 'info'"
        @close="handleClose(item)"
        @click="handleSelect(item)"
      >
        {{ item.title }}</el-tag
      >
    </div>
  </div>
</template>

<script>
import {user} from '../api'
export default {
  name: "Header",
  data() {
    return {};
  },
  props: ["handleDrawer", "wWidth", "isDrawer", "refresh"],
  computed: {
    isCollapse() {
      return this.$store.state.isCollapse;
    },
    nickname() {
      return this.$store.state.nickname;
    }
  },
  methods: {
    // 关闭tag标签
    handleClose(item) {
      this.$store.commit("closeTag", item);
    },
    // 选择tag标签
    handleSelect(item) {
      this.$store.commit("selectTag", item);
    },
    // 改变侧边栏图标状态
    changeCollapse() {
      this.$store.commit("changeCollapse");
    },
    // 改变抽屉图标状态
    changeDrawer() {
      this.handleDrawer();
    },
    // 登录状态下拉菜单
    handleCommand(command) {
      switch (command) {
        case "exit":
          user.logout().then(() => {
            this.$store.commit("logout");
            this.$router.push("/login");
            this.$message({
              message: "退出登录成功",
              type: "success",
            });
          });
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.header-top {
  width: 100%;
  height: 60px;
  line-height: 60px;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  padding: 0 20px;
  .header-right{
    float: right;
    margin-right: 50px;
    i{
      transform:scale(1.3);
    }
    .refresh{
      margin-right: 10px;
      i{
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
      
    }
  }
  .collage {
    display: inline-block;
    width: 60px;
    height: 100%;
    line-height: 60px;
    text-align: center;
    i {
      width: 24px;
      height: 24px;
      line-height: 24px;
      transform: scale(1.5);
      border-radius: 3px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        background-color: #e4e4e4;
      }
    }
  }
  .title {
    display: inline-block;
    width: 85px;
    text-align: center;
    background-color: #e4e4e4;
    font-weight: 700;
  }
}
.header-bottom {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-top: 1px solid #eee;
  .el-tag {
    cursor: pointer;
    margin: 0 2px;
    padding: 0 10px;
    line-height: 32px;
  }
}

.el-dropdown {
  text-align: center;
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>