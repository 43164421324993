<template>
  <el-container>
    <!-- 回到顶部 -->
    <el-backtop target=".el-main" :visibility-height="150"></el-backtop>
    <!-- 侧边栏屏幕宽度大于1024时显示 -->
    <transition name="aside">
    <el-aside style="width: auto" v-if="wWidth >= 1024">
      <Aside :handleSelect="handleSelect"></Aside>
    </el-aside>
    </transition>
    <!-- 否则使用抽屉 -->
    <el-drawer
      v-if="wWidth < 1024"
      direction="ltr"
      :show-close="false"
      :size="200"
      :visible.sync="drawer"
      @close="closeDrawer"
    >
      <Aside :handleSelect="handleSelect" :closeDrawer="closeDrawer"></Aside>
    </el-drawer>
    <el-container>
      <el-header>
        <Header :handleDrawer="handleDrawer" :wWidth="wWidth" :isDrawer="drawer" :refresh="refresh"></Header>
      </el-header>
      <el-main v-loading="loading">
        <transition name="main">
        <Main v-if="this.$route.name === 'home'&&this.show"></Main>
        <router-view v-if="this.show"></router-view>
        </transition>
      </el-main>
      
    </el-container>
  </el-container>
</template>

<script>
import Header from "../components/Header";
import Aside from "../components/Aside";
import Main from "../components/Main";
export default {
  name: "Home",
  components: { Header, Aside, Main },
  data() {
    return {
      wWidth: document.body.clientWidth,
      drawer: false,//抽屉开关状态
      show: true,//页面显示状态(用于页面刷新)
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
  },
  mounted() {
    // 监听窗口大小变化
    window.onresize = () => {
      this.wWidth = document.body.clientWidth;
    };
  },
  methods: {
    // 打开抽屉
    handleDrawer() {
      this.drawer = true;
    },
    // 关闭抽屉
    closeDrawer() {
      this.drawer = false;
    },
    // 选择导航菜单跳转路由
    handleSelect(item) {
      this.$router.push({
        path: "/home" + item.path,
      });
    },
    // 页面刷新
    refresh() {
      this.show = false;
      this.$nextTick(() => {
        this.$message({
          message: "刷新成功",
          type: "success",
          duration: 1000
        });
        setTimeout(() => {
          this.show = true;
        }, 100);
      });
    },
  },
};
</script>

<style lang="less">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-header {
  height: 100px !important;
  line-height: 55px;
  color: #595959;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 30%);
  box-sizing: border-box;
  font-size: 14px;
  padding: 0;
}
.el-aside {
  width: auto !important;
  background-color: #28333e;
}
.el-main {
  background-color: #f2f2f2;
}
.el-drawer{
  background-color: #28333e !important;
  header{
    height: 0;
    margin: 0;
    padding: 0;
  }
}

/* 侧边栏过渡动画 */
.aside-enter-active, .aside-leave-active{
  transition: all .2s;
}
.aside-enter, .aside-leave-to{
  width: 0 !important;
}
.aside-enter-to, .aside-leave{
  width: 200px !important;
}

/* 路由切换及页面刷新动画 */
.main-leave{
  display: none !important;
}
.main-enter-active{
  position: relative;
  transition: all .8s;
}
.main-enter-to{
  opacity: 1;
  top: 0;
}
.main-enter{
  opacity: 0;
  top: 50px;
}
</style>