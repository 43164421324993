<template>
  <div>
    <el-row :gutter="25">
      <el-col :xs="24" :sm="8" :md="6">
        <el-card body-style="height: 100px;padding:0;background-color:#2f4056">
          <div class="card">
            <div class="card-title">
              <span>目前</span>
              <h5>关联账号</h5>
            </div>
            <div class="card-content">
              <h1>{{homedata.relationnum}}</h1>
              <small>当前账号关联账号数量</small>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="8" :md="6">
        <el-card body-style="height: 100px;padding:0;background-color:#1e9fff">
          <div class="card">
            <div class="card-title">
              <span style="background-color: #2f4056">目前</span>
              <h5>代步次数</h5>
            </div>
            <div class="card-content">
              <h1>{{homedata.stepnum}}</h1>
              <small>当前已代步多少次</small>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="8" :md="6">
        <el-card body-style="height: 100px;padding:0;background-color:#393d49">
          <div class="card">
            <div class="card-title">
              <span>目前</span>
              <h5>平台总代步</h5>
            </div>
            <div class="card-content">
              <h1>{{homedata.allstep}}</h1>
              <small>平台已为全部用户代步总次数</small>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="8" :md="6">
        <el-card body-style="height: 100px;padding:0;background-color:#009688">
          <div class="card">
            <div class="card-title">
              <span>目前</span>
              <h5>当前登录IP</h5>
            </div>
            <div class="card-content">
              <h1>{{homedata.ip}}</h1>
              <small>当前登录IP</small>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :xs="24" :sm="16">
        <el-card>
          <div slot="header">
            <h3>竹水云代步发展史</h3>
          </div>
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in homedata.history"
              :key="index"
              :timestamp="item.date"
              placement="top"
            >
              <el-card>
                <!-- <div class="card-title">
                  <span>{{ item.title }}</span>
                  <h5>{{ item.name }}</h5>
                </div> -->
                <div class="card-content">
                  <p v-html="item.content.split(/\n/).join('<br>')"></p>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="8">
        <el-card>
          <div slot="header">
            <h3>作者有话说</h3>
          </div>
          <p style="font-weight: bold; color: #df514c">
            平台已稳定运行 {{ runTime }}
          </p>
          <p>此系统仅供学习交流使用，如有侵权行为请立即通知站长关闭此站点！</p>
          <div style="text-align: center">
            <svg
              t="1626542490958"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2123"
              width="200"
              height="200"
            >
              <path
                d="M762.9 487.8l-3.3-0.9 2.4-19.3c6.6-10.4 6.1-24.4-2.3-34.3-10.2-12.2-28.3-13.9-40.7-3.9 12-10.4 13.4-28.5 3.2-40.7-9.6-11.4-26-13.6-38.2-5.7l-0.3-0.4c9.9-10.6 10.5-27.2 0.9-38.5-8.9-10.6-23.6-13.3-35.4-7.3l-1-1.3c7.9-10.6 7.8-25.6-1.1-36.1-8.2-9.7-21.3-12.8-32.5-8.6l-2.1-2.7c6-10.3 5.2-23.7-2.9-33.4a29.23 29.23 0 0 0-29.4-9.6l-3.7-4.9c4.2-9.8 2.9-21.5-4.5-30.3-6.6-7.9-16.5-11.4-26-10.2l-5.7-7.6v-50.9c0-16-13.1-29.2-29.2-29.2-16 0-29.2 13.1-29.2 29.2v52.5l-4.5 5.9c-9.3-0.9-18.8 2.6-25.2 10.3-7.1 8.5-8.6 19.9-4.7 29.6l-4.1 5.4c-10.3-2.3-21.5 1.1-28.7 9.7-7.9 9.5-8.8 22.5-3.2 32.7l-2.4 3.1c-11.1-3.9-23.9-0.7-31.9 8.8-8.7 10.4-8.9 25-1.5 35.6l-1.1 1.5c-11.7-5.6-26.1-2.8-34.9 7.6-9.4 11.2-8.9 27.4 0.5 38.1l-0.4 0.5c-12.1-7.5-28.2-5.1-37.6 6.1-10.1 12-8.8 29.8 2.7 40.3v0.1c-12.3-9.5-30.1-7.7-40.2 4.3-7.9 9.4-8.8 22.4-3.3 32.6 0.5 4.2 1.6 12.8 3.1 24.5l-3.9 1c-15.5 4.2-24.8 20.2-20.6 35.7l11 41.2c27.7 45.3 43.7 98.5 43.7 155.4 0 32.6-5.2 63.9-14.9 93.2v7.6c-0.9 3.5-1.4 7.3-1.4 11.1s0.5 7.5 1.4 11.1v0.1c4.9 18.7 22 32.5 42.3 32.5 20.3 0 37.3-13.8 42.3-32.5h0.7v-0.1c41.1 41.5 93.5 65.2 146.1 65.2 52.2-0.1 105.3-23.7 147.4-64.9 5 18.6 22 32.4 42.2 32.4 20.3 0 37.3-13.8 42.3-32.5h2v-24.3c-10.9-30.9-16.8-64.2-16.8-98.8 0-55.6 15.3-107.7 41.8-152.3l12.9-48c4.7-15.5-4.6-31.5-20.1-35.7zM322.5 850.3c-8.1 0-14.6-6.5-14.6-14.6s6.5-14.6 14.6-14.6 14.6 6.5 14.6 14.6-6.5 14.6-14.6 14.6z m379.1 0c-8.1 0-14.6-6.5-14.6-14.6s6.5-14.6 14.6-14.6 14.6 6.5 14.6 14.6-6.5 14.6-14.6 14.6z m16.9-325.8l-10.7 78.2c-0.5 3.4-13.4 93.3-13.4 93.3-3.2 21.4-9.8 41.1-19.4 58.6-35.5 64.5-100.1 103.8-163.2 103.9-57.2 0.1-114.2-32.1-149.8-85.9-14.4-21.7-25-47.1-30.6-75.4l-0.3-1.5c-1.4-7.5-14.3-89-14.8-93l-10.7-78.2c-0.6-4.6 0.7-18.7 0.7-18.7 4.5-20.6 21.2-36.8 43.9-41.4H369c17.3 3.9 69 15.4 82.6 15.4h121c13.5 0 65.3-11.5 82.6-15.4H674c22.7 4.6 39.4 20.8 43.9 41.4-0.1 0 1.2 14.1 0.6 18.7z"
                fill="#dbdbdb"
                p-id="2124"
              ></path>
              <path
                d="M512 544.9m-14.6 0a14.6 14.6 0 1 0 29.2 0 14.6 14.6 0 1 0-29.2 0Z"
                fill="#dbdbdb"
                p-id="2125"
              ></path>
              <path
                d="M449.4 616.6h-81.1c-10.5 0-19.8-5.6-24.9-14v0.5c0 16.1 26.1 29.2 58.3 29.2 22.7 0 42.4-6.5 52-16-1.4 0.2-2.8 0.3-4.3 0.3zM655.8 616.6h-81.1c-1.5 0-2.9-0.1-4.4-0.3 9.6 9.5 29.3 16 52 16 32.2 0 58.3-13.1 58.3-29.2v-0.5c-5 8.3-14.3 14-24.8 14zM512 792.6c32.2 0 58.3-13.1 58.3-29.2H453.7c0 16.1 26.1 29.2 58.3 29.2z"
                fill="#dbdbdb"
                p-id="2126"
              ></path>
            </svg>
          </div>
          <p style="text-align: center">佛祖保佑，永无BUG，希望系统百毒不侵</p>
          <p style="text-align: center">南无阿弥陀佛，永不宕机</p>
          <p style="text-align: right">2021-07-18 01:18:00</p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {home} from '..//api'
export default {
  name: "Main",
  data() {
    return {
      runTime: "",// 平台运行时间
      homedata: {},// 首页数据
    };
  },
  created() {
    home.getHomeData().then((res) => {
      this.homedata = res;
    });
  },
  mounted() {
    const date1 = new Date("2021-07-18 01:18:00"); //开始时间
    const that = this;
    function fn() {
      let date2 = new Date(); //当前时间
      let date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
      //计算出相差天数
      let days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      that.runTime = days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒";
      return fn;
    }
    this.timer = setInterval(fn(), 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.el-col {
  padding: 15px;
}
.card {
  padding: 15px;
  .card-title {
    font-size: 14px;
    color: #fff;
    span {
      float: right;
      display: inline;
      padding: 0.2em 0.6em 0.3em;
      background-color: #1e9fff;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25em;
      margin-top: 0.3em;
    }
    h5 {
      margin: 0;
      font-weight: 400;
      font-size: 100%;
    }
  }
  .card-content {
    padding: 0;
    margin: 0;
    h1 {
      font-size: 1.75em;
      font-weight: 400;
      margin: 0;
      color: #fff;
    }
    small {
      font-size: 75%;
      color: #fff;
    }
  }
}
</style>